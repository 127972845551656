import axios from "axios";

console.log(process.env.NODE_ENV);
export default process.env.NODE_ENV === "production"
    ? axios.create({
        //production 환경 (AWS EC2)
        // baseURL: "http://43.201.5.202:8602",
        // baseURL: "https://was.new-thermage.com",
        baseURL: "https://was.new-thermage.com",
    })
    : axios.create({
        //development 환경
        // baseURL: "http://192.168.1.3:8602",
        // baseURL: "http://zp.wizai.co.kr:6503"
        baseURL: "http://localhost:8602",
        // baseURL: "http://43.201.5.202:8602",
        // baseURL: "https://was.new-thermage.com",
        // baseURL: "https://dev-was.new-thermage.com",
    });
