import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800px","content-class":"common-dialog"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('h5',[_vm._v("검색 결과 중에서 선택해 주세요")])]),_c(VDataTable,{attrs:{"item-key":"num","headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","items-per-page":20},on:{"click:row":_vm.clickRow}}),_c(VCardActions,[_c(VBtn,{attrs:{"depressed":"","large":""},on:{"click":_vm.cancel}},[_vm._v(" 닫기 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }