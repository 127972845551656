<template>
  <v-footer dark>
    <v-card class="flex" flat tile>
      <v-card-title>
        <p>
          <b>SOLTA MEDICAL KOREA Limited</b>
          (솔타메디칼코리아 유한회사) <br />서울시 강남구 테헤란로 504(대치동), 해성빌딩 8층 제품문의 1544-8330<br/>
          <!-- 24년 사명변경에 따른 변경 전 백업본 (03.08) -->
          <!-- <b>SOLTA MEDICAL A DIVISION OF BAUSCH HEALTH COMPANIES INC.</b>
          ㈜바슈헬스코리아(솔타메디칼) <br />서울시 강남구 테헤란로
          405,8층(대치동) 제품문의 070.7167.9799<br /> -->
          <!-- ㈜바슈헬스코리아(솔타메디칼) <br />서울시 강남구 테헤란로 98길
          8(대치동) KT&G 코스모 대치타워 13층 제품문의 070.7167.9799<br /> -->
          <b>Thermage FLX™</b> 제품은 "의료기기" 이며, "사용상의 주의사항"과
          "사용방법"을 잘 읽고 사용하십시오.<br />
          광고심의필/ 심의확인번호: 조합 2023-42-038(유효기간 2026.12.19),
          조합 2024-06-028(유효기간 2027.02,20), 조합2024-22-070 (유효기간 2027.06.10), <br/>
          조합 2024-22-071 (유효기간 2027.06.23), 조합 2024-22-072 (유효기간 2027.06.23), 
          조합 2024-22-073 (유효기간 2027.06.23)<br />
          <br />
          ⓒ SOLTA MEDICAL KOREA Limited<br/>
          
          <!-- 24년 사명변경에 따른 변경 전 백업본 (03.08) -->
          <!-- ⓒ 2018. SOLTA MEDICAL A DIVISION OF BAUSCH HEALTH COMPANIES INC. All
          Rights Reserved.<br />Solta, Fraxel and Fraxel dual are registered
          trademarks of Solta Medical, Inc. or It’s subsidiaries.<br /> -->
          <a @click="clickAgreeDetail('policy')">개인정보처리방침</a>
        </p>

        <div class="footer-btns">
          <v-btn
            outlined
            dark
            block
            large
            href="https://www.instagram.com/thermage_kr/"
            target="(blog)"
          >
            <!-- <v-icon size="20px"> mdi-post </v-icon> -->
            인스타그램
          </v-btn>
          <v-btn
            outlined
            dark
            block
            large
            href="https://blog.naver.com/thermage_kr"
            target="(blog)"
          >
            <!-- <v-icon size="20px"> mdi-post </v-icon> -->
            블로그
          </v-btn>
          <v-btn
            outlined
            dark
            block
            large
            href="https://www.facebook.com/thermageKR/"
            target="(facebook)"
          >
            <!-- <v-icon size="20px"> mdi-facebook </v-icon> -->
            페이스북
          </v-btn>
        </div>
      </v-card-title>
    </v-card>
    <!-- 약관 상세보기 팝업 -->
    <SignUpAgreeDetailDialog ref="SignUpAgreeDetailDialog" />
  </v-footer>
</template>
<script>
import SignUpAgreeDetailDialog from "../home/signUp/SignUpAgreeDetailDialog.vue";

export default {
  name: "Footer",
  components: { SignUpAgreeDetailDialog },
  computed: {},
  methods: {
    clickAgreeDetail(tab) {
      this.$refs.SignUpAgreeDetailDialog.open(tab);
    },
  },
};
</script>
<style lang="scss">
.v-footer {
  padding: 1.5rem 3rem;
  &.theme--dark {
    background-color: #000000;
  }
  .v-card {
    &.theme--dark {
      background-color: #000000;
    }
  }
  .v-card__title {
    padding: 0;
    align-items: flex-start;
    justify-content: space-between;
    p {
      font-size: 12px;
      line-height: 18px;
      a {
        color: #ffffff;
        text-decoration: underline;
      }
    }
    .v-btn {
      margin-bottom: 1rem;
    }
  }
}
@media (max-width: 768px) {
  .v-footer {
    padding: 1.5rem 1.5rem;
    .footer-btns {
      width: 100%;
    }
  }
}
</style>