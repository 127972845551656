<template>
  <div>
    <div class="page certify">
      <div class="page-section">
        <div class="section-inner">
          <div class="section-title">
            <!-- <img
            class="top-logo"
            src="@/assets/images/common/thermage_logo.png"
            alt="thermage_logo"
          /> -->
            <h1 class="main-title primary--text">정품 인증</h1>
            <p class="sub-title">
              시술 후 제공된 <b>정품 인증 번호 6자리</b> 또는 <br />
              <b>12자리</b>를 입력해 주세요. (스티커 교체 기간 병용)
            </p>
          </div>

          <p class="input-guide input-top">
            ※부착된 QR코드를 촬영하여 모바일에서 인증하실 수 있습니다.
          </p>
          <v-text-field
            solo
            placeholder="정품인증번호를 입력해 주세요."
            ref="productCode"
            v-model="reqData.productCode"
          ></v-text-field>
          <div class="submit-btn-wrap">
            <v-btn
              depressed
              color="primary"
              x-large
              block
              @click="submitCertify"
              >정품 인증하기</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <div class="reference">
      <div class="section-inner">
        <p class="part-number mt-6">KTMG-20240122-042, KTMG-20240620-088</p>
      </div>
    </div>
    <!-- 임시 시스템점검 팝업 처리 -->
    <!-- <TempNoticeDialog2 ref="TempNoticeDialog2" /> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
// 임시 시스템점검 팝업 처리
// import TempNoticeDialog2 from "@/components/common/dialog/TempNoticeDialog2.vue";
const formatService = require("@/utils/format.js");
export default {
  // 임시 시스템점검 팝업 처리
  // components: { TempNoticeDialog2 },
  data() {
    return {
      reqData: {
        endpoint: "certify",
        authType: "qr",
        productCode: "",
      },
      requiredMsg: formatService.requiredFieldAlertMessage(),
    };
  },
  mounted() {
    // this.$refs.TempNoticeDialog2.open();
    const authkey = this.$route.query.authkey;
    // console.log("authkey", authkey);
    if (authkey === undefined) {
      this.reqData.authType = "serial";
      return;
    }
    this.reqData.productCode = authkey;
    this.submitCertify();
  },
  methods: {
    submitCertify() {
      // 필수처리
      const D = this.reqData;
      const RF = this.$refs;

      if (
        !this.$helper.requiredCheck(
          D.productCode,
          RF.productCode,
          `정품인증 코드는 ${this.requiredMsg}`,
          false
        )
      ) {
        RF.productCode.focus();
        return;
      }
      this.saveAuthType(D);
      this.saveCertify("");
      this.getDataQuery(D).then((res) => {
        console.log("", res);
        if (res === undefined) return;
        if (!res.checkVal) {
          // if (res.msg === "redirectNormalEventPage") {
          //   await this.$refs.TempNoticeDialog2.open(D);
          //   return;
          // }
          let msg = "올바른 정품인증코드를 입력해주세요";
          let certifyDate = "";
          if (!res.checkVal) {
            certifyDate =
              res.certifyDate === null
                ? ""
                : this.$moment(res.certifyDate).format(
                    "YYYY년 MM월 DD일 HH시mm분"
                  );
            msg =
              certifyDate === ""
                ? `이미 정품인증된 코드입니다`
                : `해당 정품 인증 번호는 ${certifyDate} 인증이 완료되었습니다.`;
          }
          if (res.msg === "") {
            msg = "올바른 정품인증코드를 입력해주세요";
          }
          this.$helper.showTostMessage("top", msg, "error");
          RF.productCode.focus();
          return;
        }
        // 정품인증 코드저장
        this.saveCertify(D);
        this.$router.push("/certify/authenticated");
      });
    },
    ...mapActions(["getDataQuery", "saveCertify", "saveAuthType"]),
  },
};
</script>

<style lang="scss">
.page.certify {
  background: linear-gradient(#ffffff, #ebe5e1);
  .page-section {
    text-align: center;
    // height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    .submit-btn-wrap {
      margin-top: 40px;
    }
  }
}
</style>
