import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Cookies from "js-cookie";
import VueMoment from 'vue-moment';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import "babel-polyfill";
import * as helpers from '@/utils/helper';
import * as Sentry from "@sentry/vue";
// import vueVimeoPlayer from 'vue-vimeo-player'

Vue.config.productionTip = false;

Vue.prototype.$helper = helpers;
Vue.use(VueMoment);
Vue.use(VueToast);
// Vue.use(vueVimeoPlayer);

const savedToken = Cookies.get("accessToken");
if (savedToken) {
  store.dispatch("signinByToken", savedToken).then(() => {
  });
}

Sentry.init({
  Vue,
  dsn: "https://79c9c1666d653c3fa173ad85ea40fb6a@o4508753650909184.ingest.us.sentry.io/4508753652482048",
  integrations: [],
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// social login 
window.Kakao.init('75895f597bdf80fa525efbbdaa1a86c8') // key