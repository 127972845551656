import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"agree"},[_c('div',{staticClass:"section-inner"},[_vm._m(0),_c(VForm,{ref:"form"},[_c('div',{staticClass:"check-group"},[_c('h4',[_c(VCheckbox,{attrs:{"label":"전체 동의"},on:{"click":function($event){return _vm.clickAll(_vm.userData.agreeAll)}},model:{value:(_vm.userData.agreeAll),callback:function ($$v) {_vm.$set(_vm.userData, "agreeAll", $$v)},expression:"userData.agreeAll"}})],1),_c('div',{staticClass:"checkbox-row"},[_c(VCheckbox,{attrs:{"required":"","label":"개인정보 수집 동의 [필수]","rules":[
              function () { return !!_vm.userData.marketing || '이용약관 동의는 필수 입니다.'; } ]},model:{value:(_vm.userData.marketing),callback:function ($$v) {_vm.$set(_vm.userData, "marketing", $$v)},expression:"userData.marketing"}}),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.clickAgreeDetail('term')}}},[_vm._v(" 보기 ")])],1),_c('div',{staticClass:"checkbox-row"},[_c(VCheckbox,{attrs:{"required":"","label":"개인정보 처리방침 항목 동의 [필수]","rules":[
              function () { return !!_vm.userData.agreeTerms ||
                '개인정보처리방침 동의는 필수 입니다.'; } ]},model:{value:(_vm.userData.agreeTerms),callback:function ($$v) {_vm.$set(_vm.userData, "agreeTerms", $$v)},expression:"userData.agreeTerms"}}),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.clickAgreeDetail('policy')}}},[_vm._v(" 보기 ")])],1),_c('div',{staticClass:"checkbox-row"},[_c(VCheckbox,{attrs:{"label":"의,약학적 정보 전달 및 제품 홍보 등 마케팅 안내 관련 동의 [선택]"},model:{value:(_vm.userData.agreePolicy),callback:function ($$v) {_vm.$set(_vm.userData, "agreePolicy", $$v)},expression:"userData.agreePolicy"}}),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.clickAgreeDetail('medical')}}},[_vm._v(" 보기 ")])],1)]),_c(VBtn,{attrs:{"color":"primary","x-large":"","depressed":"","block":""},on:{"click":_vm.clickNext}},[_vm._v("다음")]),_c('div',{staticClass:"hGap"}),_c(VBtn,{attrs:{"color":"","x-large":"","depressed":"","block":""},on:{"click":_vm.clickCancel}},[_vm._v("취소")])],1)],1),_c('SignUpAgreeDetailDialog',{ref:"SignUpAgreeDetailDialog"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-header"},[_c('img',{staticClass:"top-logo",attrs:{"src":require("@/assets/images/common/thermage_logo.png"),"alt":"app_logo"}}),_c('p',{staticClass:"sub-title"},[_vm._v(" 본 자료실은 보건의료전문가만"),_c('br'),_vm._v(" 열람 가능 합니다."),_c('br'),_c('strong',[_vm._v("정품인증시")]),_vm._v("에 "),_c('strong',[_vm._v("별도의 회원가입 필요 없이 진행 가능")]),_vm._v("하며,"),_c('br'),_vm._v(" 병원 관계자가 아닌 경우 회원가입시에는 회원가입이 불가능한 점"),_c('br'),_vm._v(" 숙지 부탁드립니다. ")])])}]

export { render, staticRenderFns }